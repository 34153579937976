<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text class="d-flex">
        <v-avatar
          rounded
          size="120"
          class="me-6"
        >
          <v-img :src="cropped"></v-img>
        </v-avatar>

        <!-- upload photo -->
        <div>
          <v-btn
            color="primary"
            class="me-3 mt-5"
            @click="$refs.refInputEl.click()"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span class="d-none d-sm-block">Upload new photo</span>
          </v-btn>

          <input
            ref="refInputEl"
            type="file"
            accept=".jpeg,.png,.jpg"
            :hidden="true"
            v-on:click="$refs.refInputEl.value = ''"
            v-on:change="croppie"
          />

          <v-btn
            color="error"
            outlined
            class="mt-5"
            @click="resett()"
          >
            Reset
          </v-btn>
          <p class="text-sm mt-5">
            Allowed JPG or PNG
          </p>
        </div>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-select
                v-model="type_of_member"
                dense
                outlined
                label="Type of Member"
                :items="['Beneficiary','Regular','Volunteer','Scholar']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-select
                v-model="district"
                dense
                outlined
                label="District/Club"
                :items="district_items"
                item-value="id"
                item-text="district_name"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                type="date"
                v-model="date_accomplished"
                label="Date Accomplished"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="cmf_no"
                label="CMF No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="place_of_birth"
                      dense
                      label="Place of Birth"
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male','Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="contact_no"
                      dense
                      outlined
                      label="Contact Number/s"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="email_address"
                      dense
                      outlined
                      label="Email Address"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-model="civil_status"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single','Married','Widowed','Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="occupation"
                      label="Occupation"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-text-field
                      v-model="permanent_address"
                      dense
                      outlined
                      label="Permanent Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="zip_code"
                      dense
                      outlined
                      label="Zip Code"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-combobox
                      :append-icon="icons.mdiAccountSearchOutline"
                      v-model="name_of_sponsor"
                      @keyup.enter="searching_search_items($event.target.value)"
                      :items="search_items"
                      item-value="id"
                      item-text="name"
                      placeholder="Name of Referer"
                      ref="memberCombobox"
                      dense
                      outlined
                    ></v-combobox>
                  </v-col>

                  <v-checkbox
                    v-model="is_with_signature"
                    label="With Signature"
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_member"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>


    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline
        },
      }
    },
    created() {
      this.member_counter_data()
    },
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',
        croppieImage: '',
        cropped: this.avatarImg,
        district_items: [],
        district: '',

        name_of_sponsor: '',
        search_items: [],

        type_of_member: '',
        date_accomplished: '',
        cmf_no: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        place_of_birth: '',
        gender: '',
        tin_no: '',
        sss_no: '',
        religion: '',
        civil_status: '',
        no_of_dependents: '',
        educational_attainment: '',
        present_address: '',
        residence_is: '',
        residence_specify: '',
        permanent_address: '',
        zip_code: '',
        citizenship: '',
        contact_no: '00000000000',
        email_address: '',
        family_last_name: '',
        family_first_name: '',
        family_middle_name: '',
        family_date_of_birth: '',
        family_contact_no: '',
        family_occupation: '',
        family_employer: '',
        family_employer_address: '',
        family_monthly_income: '',
        family_employment_status: '',
        family_employment_date: '',
        occupation: '',
        employment_date: '',
        employer: '',
        employer_address: '',
        employer_contact_no: '',
        monthly_income: '',
        employment_status: '',
        ref_name_1: '',
        ref_address_1: '',
        ref_contact_no_1: '',
        ref_relation_1: '',
        ref_name_2: '',
        ref_address_2: '',
        ref_contact_no_2: '',
        ref_relation_2: '',
        type_of_client: '',
        type_of_client_specify: '',
        is_with_signature: true,
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'district_id', 'district_name']),
      ...mapGetters('transaction_months', ['month_start', 'month_end', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    mounted() {
      if (this.district_id === null) {
        this.list_of_district()
          .then(response => {
            this.district_items = response.data
          })
      } else {
        this.district_items.push({
          'id': this.district_id,
          'district_name': this.district_name,
        })
      }
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['save_new_members_information', 'member_counter', 'is_members_already_encoded']),
      ...mapActions('district', ['list_of_district']),
      ...mapActions('members_information', ['search_all_members']),
      searching_search_items(value) {
        this.search_all_members({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.name_of_sponsor = ''
        this.croppieImage = ''
        this.cropped = this.avatarImg
        this.date_accomplished = ''
        this.cmf_no = ''
        this.last_name = ''
        this.first_name = ''
        this.middle_name = ''
        this.date_of_birth = ''
        this.place_of_birth = ''
        this.gender = ''
        this.tin_no = ''
        this.sss_no = ''
        this.religion = ''
        this.civil_status = ''
        this.no_of_dependents = ''
        this.educational_attainment = ''
        this.present_address = ''
        this.residence_is = ''
        this.residence_specify = ''
        this.permanent_address = ''
        this.zip_code = ''
        this.citizenship = ''
        this.contact_no = ''
        this.email_address = ''
        this.family_last_name = ''
        this.family_first_name = ''
        this.family_middle_name = ''
        this.family_date_of_birth = ''
        this.family_contact_no = ''
        this.family_occupation = ''
        this.family_employer = ''
        this.family_employer_address = ''
        this.family_monthly_income = ''
        this.family_employment_status = ''
        this.family_employment_date = ''
        this.occupation = ''
        this.employment_date = ''
        this.employer = ''
        this.employer_address = ''
        this.employer_contact_no = ''
        this.monthly_income = ''
        this.employment_status = ''
        this.ref_name_1 = ''
        this.ref_address_1 = ''
        this.ref_contact_no_1 = ''
        this.ref_relation_1 = ''
        this.ref_name_2 = ''
        this.ref_address_2 = ''
        this.ref_contact_no_2 = ''
        this.ref_relation_2 = ''
        this.type_of_client = ''
        this.type_of_client_specify = ''
        this.is_with_signature = true
      },
      croppie(e) {
        this.show = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg
      },
      member_counter_data() {
        this.member_counter()
          .then(response => {
            this.cmf_no = moment().format('YYYY') + '-' + (response.data + 1)
          })
          .catch(error => {
            this.saving = false
          })
      },
      save_member() {
        this.saving = true
        this.alert = false
        if (this.name_of_sponsor === '') {
          this.alert = true
          this.alert_message = 'Please Select Referer'
          this.saving = false
        } else {
          const str = this.contact_no;
          const firstTwoChars = str.slice(0, 2);
          if (firstTwoChars != '09') {
            this.alert = true
            this.alert_message = 'CONTACT # MUST START 09'
            this.saving = false
          } else {
            var proceed = true;
            if (this.name_of_sponsor != null) {
              if (this.name_of_sponsor.id === undefined) {
                this.alert = true
                this.alert_message = 'Please Search Referer First'
                this.saving = false
                proceed = false
              }
            }
            if (proceed) {
              if (this.$refs.form.validate()) {
                const data = new FormData()
                data.append('last_name', this.last_name.toUpperCase());
                data.append('first_name', this.first_name.toUpperCase());
                data.append('middle_name', this.middle_name.toUpperCase());
                this.is_members_already_encoded(data)
                  .then(response => {
                    if (response.data === 1) {
                      this.alert = true
                      this.alert_message = 'Member Already Encoded!'
                      this.saving = false
                    } else {
                      data.append('profile_picture', this.croppieImage);
                      data.append('branch_id', this.branch_id);
                      data.append('refer_from_id', this.name_of_sponsor.id);
                      data.append('cmf_no', this.cmf_no);
                      data.append('month_of', this.month_of);
                      data.append('date_accomplished', moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                      data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                      data.append('place_of_birth', this.place_of_birth);
                      data.append('gender', this.gender);
                      data.append('tin_no', this.tin_no);
                      data.append('sss_no', this.sss_no);
                      data.append('religion', this.religion);
                      data.append('civil_status', this.civil_status);
                      data.append('no_of_dependents', this.no_of_dependents);
                      data.append('educational_attainment', this.educational_attainment);
                      data.append('present_address', this.present_address);
                      data.append('residence_is', this.residence_is);
                      data.append('residence_specify', this.residence_specify);
                      data.append('permanent_address', this.permanent_address);
                      data.append('zip_code', this.zip_code);
                      data.append('citizenship', this.citizenship);
                      data.append('contact_no', this.contact_no);
                      data.append('email_address', this.email_address);
                      data.append('occupation', this.occupation);
                      data.append('employment_date', this.employment_date);
                      data.append('employer', this.employer.toUpperCase());
                      data.append('employer_address', this.employer_address);
                      data.append('employer_contact_no', this.employer_contact_no);
                      data.append('monthly_income', this.monthly_income);
                      data.append('employment_status', this.employment_status);
                      data.append('ref_name_1', this.ref_name_1.toUpperCase());
                      data.append('ref_address_1', this.ref_address_1);
                      data.append('ref_contact_no_1', this.ref_contact_no_1);
                      data.append('ref_relation_1', this.ref_relation_1);
                      data.append('ref_name_2', this.ref_name_2.toUpperCase());
                      data.append('ref_address_2', this.ref_address_2);
                      data.append('ref_contact_no_2', this.ref_contact_no_2);
                      data.append('ref_relation_2', this.ref_relation_2);
                      data.append('type_of_client', this.type_of_client);
                      data.append('type_of_client_specify', this.type_of_client_specify.toUpperCase());
                      data.append('district_id', this.district);
                      data.append('type_of_member', this.type_of_member);
                      var with_signature = 0
                      if (this.is_with_signature) {
                        with_signature = 1
                      }
                      data.append('is_with_signature', with_signature);
                      this.save_new_members_information(data)
                        .then(response => {
                          this.change_snackbar({
                            show: true,
                            color: 'success',
                            text: response.data,
                          })
                          this.reset()
                        })
                        .catch(error => {
                          this.alert = true
                          this.alert_message = error
                          console.log(error)
                          this.saving = false
                        })
                    }
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
              } else {
                this.alert = true
                this.alert_message = 'Please Fill up the field/s'
                this.saving = false
              }
            }
          }
        }
      }
    }
  }
</script>
